import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { IonApp, IonContent, IonHeader, IonMenu, IonRouterOutlet, IonTitle, IonToolbar, PopoverController } from '@ionic/angular/standalone';
// Services
import { AuthService } from './services/auth.service';
import { SettingsService } from './services/settings.service';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IonApp, IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonRouterOutlet, UserMenuComponent],
})
export class AppComponent implements OnInit {
  public popoverController = inject(PopoverController);
  public authService = inject(AuthService);
  public settingsService = inject(SettingsService);
  private analyticsService = inject(AnalyticsService);

  async ngOnInit() {
    this.analyticsService.initialize();
    this.settingsService.init();
    await this.authService.init();
  }
}
