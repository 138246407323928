import { Injectable, effect, inject, signal, untracked } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import store from 'store2';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public theme = signal<Theme>('light');
  public infoBoxVisible = signal<boolean>(false);
  private cookieService = inject(CookieService);

  constructor() {
    effect(() => {
      const theme = this.theme();
      untracked(() => {
        if (theme) {
          this.setTheme(theme);
        }
      });
    });
    effect(() => {
      const infoBoxVisible = this.infoBoxVisible();
      untracked(() => {
        this.setInfoBoxVisible(infoBoxVisible);
      });
    });
  }

  /**
   * Call on init
   */
  init() {
    const theme = this.getTheme();
    this.theme.set(theme);

    this.initInfoBox();
  }

  private setTheme(theme: Theme) {
    switch (theme) {
      case 'light':
        document.body.classList.remove('dark');
        document.body.classList.add('light');
        break;
      case 'dark':
        document.body.classList.remove('light');
        document.body.classList.add('dark');
        break;
      default:
        return;
    }

    console.log('Set theme to', theme);
    return this.cookieService.set('theme', theme);
  }

  private getTheme() {
    const theme = this.cookieService.get('theme') || 'dark';
    return theme as Theme;
  }

  private setInfoBoxVisible(visible: boolean) {
    store.set('info-box-visible', visible);
  }

  private initInfoBox() {
    const infoBoxVisible = store.get('info-box-visible');
    if (infoBoxVisible === null) {
      this.infoBoxVisible.set(true);
    } else {
      this.infoBoxVisible.set(infoBoxVisible);
    }
  }
}

export type Theme = 'dark' | 'light';
