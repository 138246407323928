export const environment = {
  production: true,
  // apiUrl: 'https://api.corsproxy.io/v1',
  apiUrl: 'https://api.corsproxy.io/v1',
  clerk: {
    apiUrl: 'https://clerk.corsproxy.io',
    publicKey: 'pk_live_Y2xlcmsuY29yc3Byb3h5LmlvJA',
    redirectUrl: 'https://console.corsproxy.io/'
  },
  cloudflare: {
    apiUrl: 'https://api.cloudflare.com/client/v4',
    zoneId: '7c71f490feb98b42d9c91f43abf97963',
    apiToken: 'g48fk4wgNlzXfxpkINdQtrelH4iTMi939OB_HGyN'
  },
  stripe: {
    publishableKey: 'pk_live_51NpVcgG4ChEBh29vesfa6QbiUXinvCUQcL8VmA5jCocbx6bNR3NHsXrEFxyS7aRTeZ7uQS9HAMEDQ5usiOyiHo1T00GyXEZ14b',
    returnUrl: 'https://console.corsproxy.io'
  },
  lemonSqueezy: {
    shopUrl: 'https://checkout.corsproxy.io',
    returnUrl: 'https://console.corsproxy.io'
  }
};
