import { effect, inject, Injectable, NgZone, untracked } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { RestService } from './rest.service';
import posthog, { EventName, Properties } from 'posthog-js';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private navigationEnd: Observable<NavigationEnd>;
  private authService = inject(AuthService);
  private restService = inject(RestService);
  private ngZone = inject(NgZone);

  constructor(public router: Router) {
    this.navigationEnd = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;

    effect(() => {
      const user = this.authService.user();
      untracked(() => {
        if (user) {
          this.ngZone.runOutsideAngular(() => {
            posthog.identify(user.id, {
              email: user?.primaryEmailAddress,
            });
          });
        }
      });
    });

    effect(() => {
      const subscriptionChange = this.restService.onSubscriptionChange();
      untracked(() => {
        if (subscriptionChange) {
          this.ngZone.runOutsideAngular(() => {
            posthog.capture('subscription_change', subscriptionChange);
          });
        }
      });
    });
  }

  initialize() {
    this.autoCapturePageViews();
  }

  public captureEvent(event: EventName, properties?: Properties) {
    this.ngZone.runOutsideAngular(() => {
      return posthog.capture(event, properties);
    });
  }

  private autoCapturePageViews() {
    this.navigationEnd.subscribe((event: NavigationEnd) => {
      this.ngZone.runOutsideAngular(() => {
        posthog.capture('$pageview');
      });
    });
  }
}
