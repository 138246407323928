import { DecimalPipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { format, fromZonedTime, toZonedTime } from 'date-fns-tz';
import humanFormat from 'human-format';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  /**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
  public humanFileSize(bytes?: number, si = true, dp = 2) {

    if (bytes === undefined) {
      return 'N/A';
    }

    // Don't show decimal places values less than 1GB
    const _1gb = 1024 * 1_000_000;
    if (bytes < _1gb) {
      dp = 0;
    }

    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return Intl.NumberFormat().format(Number(bytes.toFixed(dp))) + ' ' + units[u];
  }

  public formatNumber(value?: number): string {

    if (value === undefined) {
      return 'N/A';
    }

    if (value > 1000000) {
      return humanFormat(value, { decimals: 1, maxDecimals: 2 });
    } else {
      return Intl.NumberFormat().format(value);
    }

  }

  /**
   * Take UTC time string in ISO-8601 format and convert it to local time in milliseconds
   * @param originalTime ISO-8601 Time string
   * @returns Milliseconds since Unix Epoch
   */
  public iso8601ToMilliseconds(originalTime: string | number | Date) {
    const zonedDate = fromZonedTime(originalTime, 'UTC');
    return zonedDate.getTime() / 1000;
  }

  /**
   * Takes local time in milliseconds and converts it to UTC time in ISO-8601 format
   * @param unixTime Milliseconds since Unix Epoch
   * @returns ISO-8601 Time string
   */
  public unixToIso8601Utc(date: string | number | Date): string {
    const iso8601 = "yyyy-MM-dd'T'HH:mm:ss'Z'";
    return format(toZonedTime(date, 'UTC'), iso8601);
  }

  /**
   * Calculate the percentage change between two numbers
   * 
   * @param current 
   * @param previous 
   * @returns 
   */
  public calculatePercentChange(current: number, previous: number): string {

    if (current === undefined || previous === undefined) {
      return 'N/A';
    }

    current = Number(current);
    previous = Number(previous);

    const result = 100 * Math.abs((current - previous) / ((current + previous) / 2));
    return parseFloat(result.toFixed(2)).toLocaleString() + '%';
  }

  public formatPercent(value?: number | null): string | null {
    if (!value && value !== 0) {
      return null;
    }
    return parseFloat(value.toFixed(2)).toLocaleString() + '%';
  }

  public crc32(str: string): string {

    if (!str) {
      return 'N/A';
    }

    const crcTable = this.makeCRCTable();
    let crc = 0 ^ (-1);

    for (let i = 0; i < str.length; i++) {
      const byte = str.charCodeAt(i);
      crc = (crc >>> 8) ^ crcTable[(crc ^ byte) & 0xFF];
    }

    crc = crc ^ (-1);

    // Convert number to hexadecimal string and pad with leading zeros if necessary
    return (crc >>> 0).toString(16).padStart(8, '0');
  }

  private makeCRCTable(): number[] {
    const crcTable: number[] = [];
    for (let n = 0; n < 256; n++) {
      let c = n;
      for (let k = 0; k < 8; k++) {
        if (c & 1) {
          c = 0xEDB88320 ^ (c >>> 1);
        } else {
          c = c >>> 1;
        }
      }
      crcTable[n] = c;
    }
    return crcTable;
  }
}

