import { Injectable, computed, inject, signal } from '@angular/core';
import { default as Clerk } from '@clerk/clerk-js';
import { dark } from '@clerk/themes';
import { UserResource } from '@clerk/types';
import { environment } from 'src/environments/environment';
import { SettingsService } from './settings.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user = signal<UserResource | null | undefined>(null);
  public ready = signal<boolean>(false);
  private clerk!: Clerk;
  private settingsService = inject(SettingsService);
  private utilsService = inject(UtilsService);

  constructor() { }

  /**
   * Initialize the Clerk instance.
   * Call this method in the app's initialization.
   */
  async init() {
    // https://clerk.com/docs/references/javascript/clerk/clerk#clerk
    this.clerk = new Clerk(environment.clerk.publicKey, {
      domain: environment.clerk.apiUrl,
    });
    // https://clerk.com/docs/references/javascript/clerk/clerk#load
    await this.clerk.load({
      afterSignInUrl: environment.clerk.redirectUrl,
      afterSignUpUrl: environment.clerk.redirectUrl
    });

    const user = this.clerk.user;
    this.user.set(user);
    this.ready.set(true);
    if (!user) {
      // Disable UI in background
      document.body.style.pointerEvents = 'none';
      // Show sign in modal
      await this.openSignInModal();
    }

    this.clerk.addListener((event) => {
      console.log('CLERK EVENT:', event);
      const user = event?.user;
      if (user) {
        console.log('CLERK USER:', event.user);
        this.user.set(user);
        if (event?.client?.signIn?.status) {
          
        }
      }
    });
  }

  /**
   * Get the current user
   */
  getUser() {
    return this.clerk.user;
  }

  /**
   * Get the API Key of the current user
   */
    getClientId() {
      return computed(() => this.utilsService.crc32(this.user()?.id || ''));
    }

  /**
   * Open user account page in a new tab
   */
  openUserAccount() {
    const url = this.clerk.buildUserProfileUrl();
    window.open(url, '_blank');
  }

  /**
    * Open the Clerk sign in modal.
    */
  async openSignInModal(): Promise<void> {
    const theme = this.settingsService.theme();
    this.clerk.openSignIn({
      appearance: {
        baseTheme: theme === 'dark' ? dark : undefined,
        variables: {
          colorPrimary: this.getCssVar('--ion-color-primary'),
          colorBackground: this.getCssVar('--ion-card-background')
        }
      },
    });

    setTimeout(() => {
      const el = document.querySelector('.cl-modalBackdrop')
      el?.addEventListener('click', (event) => console.log('CLICKKKKK', event));
      console.log(el);
    }, 500);

    // const url = this.clerk.buildSignInUrl();
    // window.open(url, '_blank');
  }

  /**
   * Sign out the current user.
   */
  async signOut(): Promise<void> {
    await this.clerk.signOut();
    window.location.reload();
  }

  getToken() {
    return this.clerk.session?.getToken();
  }

  private getCssVar(name: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(name);
  }
}
