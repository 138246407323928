import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, Router, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { provideHttpClient } from '@angular/common/http';

import posthog from 'posthog-js'
import * as Sentry from "@sentry/angular";

if (environment.production) {
  enableProdMode();

  posthog.init(
    'phc_ItMFP3E7rtBapLnS9FPapVjUpgJBYtHi76dULIIx7EX',
    {
      api_host:'https://usage.corsproxy.io/ingest',
      ui_host:'https://eu.posthog.com',
      person_profiles: 'identified_only',
      capture_pageview: false
    }
  )

  Sentry.init({
    dsn: "https://11765f0addac41307e5859512454a063@o453168.ingest.us.sentry.io/4508438648193024",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://api.corsproxy.io"],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(),
    provideIonicAngular({
      mode: 'ios',
      spinner: 'crescent'
    }),
    provideRouter(routes),
    importProvidersFrom(CookieService),
  ],
});
