<ion-list class="user-menu" lines="full">
  <ion-item *ngFor="let item of menuItems()" (click)="item?.action()" detail="false" href="#">
    <ion-icon [name]="item?.icon" color="primary" slot="start"></ion-icon>
    <ion-label>
      {{item.title}}
    </ion-label>
  </ion-item>
  <ion-item class="dark-mode">
    <ion-icon name="information-circle" color="primary" slot="start"></ion-icon>
    <ion-label>
      Show Intro
    </ion-label>
    <ion-toggle slot="end" [ngModel]="infoBoxVisible()" (ionChange)="toggleInfoBox($event)" [enableOnOffLabels]="true"></ion-toggle>
  </ion-item>
  <ion-item class="dark-mode">
    <ion-icon name="moon" color="primary" slot="start"></ion-icon>
    <ion-label>
      Dark Mode
    </ion-label>
    <ion-toggle slot="end" [ngModel]="darkMode()" (ionChange)="toggleDarkMode($event)" [enableOnOffLabels]="true"></ion-toggle>
  </ion-item>
  <ion-item detail="false" (click)="authService.signOut()">
    <ion-icon name="log-out" color="primary" slot="start"></ion-icon>
    <ion-label>
      Logout
    </ion-label>
  </ion-item>
</ion-list>