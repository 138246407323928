import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    title: 'Dashboard | CorsProxy',
    path: '',
    loadComponent: () => import('./pages/dashboard/dashboard.page').then(m => m.DashboardPage)
  },
  {
    path: 'plans',
    loadComponent: () => import('./pages/plans/plans.page').then( m => m.PlansPage)
  },
  {
    path: 'admin',
    loadComponent: () => import('./pages/admin/admin.page').then( m => m.AdminPage)
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
