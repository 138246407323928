import { Injectable, inject, signal } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  httpClient = inject(HttpClient);
  authService = inject(AuthService);
  onSubscriptionChange = signal<any | null>(null);

  async getChart(data: AnalyticsRequest): Promise<TimeSeries[]> {
    const token = await this.authService.getToken();
    const request = this.httpClient.post(environment.apiUrl + `/analytics/chart`, JSON.stringify({
      hostname: data?.hostname,
      since: data?.since,
      until: data?.until
    }), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return lastValueFrom(request) as any;
  }

  async getGroups(data: AnalyticsRequest): Promise<Group[]> {
    const token = await this.authService.getToken();
    const request = this.httpClient.post(environment.apiUrl + `/analytics/groups`, JSON.stringify({
      hostname: data?.hostname,
      since: data?.since,
      until: data?.until
    }), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return lastValueFrom(request) as any;
  }

  async getUsage(data: AnalyticsRequest): Promise<UsageResult> {
    const token = await this.authService.getToken();
    const request = this.httpClient.post(`${environment.apiUrl}/analytics/usage${environment.production ? '' : '?env=dev'}`, JSON.stringify({
      since: data?.since,
      until: data?.until
    }), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return lastValueFrom(request) as any;
  }

  verifyToken(): Promise<any> {
    const request = this.httpClient.get(`${environment.cloudflare.apiUrl}/user/tokens/verify`, {
      headers: {
        'Authorization': `Bearer ${environment.cloudflare.apiToken}`,
        'Content-Type': 'application/json'
      }
    });
    return lastValueFrom(request);
  }

  /**
   * Creates a checkout session for the given variantId and opens the checkout page
   *
   * @param variantId 
   * @returns 
   */
  async createCheckoutSession(variantId: number) {
    const token = await this.authService.getToken();
    const request = this.httpClient.post(`${environment.apiUrl}/billing/checkout`, JSON.stringify({
      variantId
    }), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const response = await lastValueFrom(request) as any;
    const url = response?.attributes?.url;

    if (url) {
      window.open(url, '_blank');
    }

    return response;
  }

  async updateUserSubscription(variantId: number) {
    const token = await this.authService.getToken();
    const request = this.httpClient.put(`${environment.apiUrl}/billing/subscription/update`, JSON.stringify({
      variantId
    }), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const response = await lastValueFrom(request) as any[];
    this.onSubscriptionChange.set(response);
    return response?.reverse()?.find(sub => sub.attributes.status === 'active');
  }

  async getUserSubscriptions() {
    const token = await this.authService.getToken();
    const request = this.httpClient.get(`${environment.apiUrl}/billing/subscription`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const response = await lastValueFrom(request) as any[];
    return response;
  }

  async openBillginPortal() {
    const subscriptions = await this.getUserSubscriptions();
    const subscription = subscriptions[0];
    const url = subscription?.attributes?.urls?.customer_portal;
    window.open(url, '_blank');
  }

  async getVariants() {
    const request = this.httpClient.get(`${environment.apiUrl}/billing/variants`);

    const response = await lastValueFrom(request) as any[];
    return response;
  }

  async addDomain(domain: string) {
    const token = await this.authService.getToken();
    const request = this.httpClient.post(`${environment.apiUrl}/account/domain?domain=${domain}`, null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return await lastValueFrom(request);
  }

  async removeDomain(domain: string) {
    const token = await this.authService.getToken();
    const request = this.httpClient.delete(`${environment.apiUrl}/account/domain?domain=${domain}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return await lastValueFrom(request);
  }

  async getDomains() {
    const token = await this.authService.getToken();
    console.log('getDomains- Token:', token);
    const request = this.httpClient.get(`${environment.apiUrl}/account/domains`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const response = await lastValueFrom(request) as any;
    return response?.domains || [];
  }

  async getPricingTable() {
    const request = this.httpClient.get(`${environment.apiUrl}/billing/pricing-table${environment.production ? '' : '?env=dev'}`, {
      headers: {
        Accept: 'application/json',
      }
    });
    const response = await lastValueFrom(request) as any;
    return response;
  }

  getLeaderboard() {
    const request = this.httpClient.get(`${environment.apiUrl}/analytics/admin/leaderboard`);
    return lastValueFrom(request) as any;
  }

}

/**
 * Analytics Types
 */

export type Period = '1h' | '24h' | '3d' | '7d';

export interface Group {
  id: string;
  title: string;
  description?: string;
  data: GroupData[] | null;
}

export interface GroupData {
  label: string;
  values: {
    [key in Metric]: number;
  }
  percent: {
    [key in Metric]: number;
  }
}

export interface TimeSeries {
  time: string;
  requests: number;
  bytes: number;
}

export interface AnalyticsRequest {
  hostname: string;
  since: string;
  until: string;
}

export interface UsageResult {
  until: string,
  since: string,
  requests: {
    total: number,
    used: number,
    percent: number
  },
  bytes: {
    total: number,
    used: number,
    percent: number
  }
}

export type Metric = 'requests' | 'bytes';

/**
 * Billing Types
 */

export interface Subscription {
  type: string;
  id: string;
  attributes: Attributes;
}
export interface Attributes {
  store_id: number;
  customer_id: number;
  order_id: number;
  order_item_id: number;
  product_id: number;
  variant_id: number;
  product_name: string;
  variant_name: string;
  user_name: string;
  user_email: string;
  status: string;
  status_formatted: string;
  card_brand: string;
  card_last_four: string;
  pause?: null;
  cancelled: boolean;
  trial_ends_at?: null;
  billing_anchor: number;
  first_subscription_item: FirstSubscriptionItem;
  urls: Urls;
  renews_at: string;
  ends_at?: null;
  created_at: string;
  updated_at: string;
  test_mode: boolean;
}
export interface FirstSubscriptionItem {
  id: number;
  subscription_id: number;
  price_id: number;
  quantity: number;
  is_usage_based: boolean;
  created_at: string;
  updated_at: string;
}
export interface Urls {
  update_payment_method: string;
  customer_portal: string;
  customer_portal_update_subscription: string;
}
